<template>
  <div>
    <b-list-group flush>
      {{ $t("pages.physicalObjects.coordinates") }}
      <b-list-group-item
        v-for="(item, index) in content"
        :key="'coordinates-' + index"
      >
        <b-row>
          <b-col>
            <b-form-checkbox
              v-model="item.current"
              :name="'form-element-current-' + index"
              switch
              @change="toggleCurrent"
            >
              {{
                item.current
                  ? $t("pages.physicalObjects.coordinatescurrent")
                  : $t("pages.physicalObjects.coordinatesprevious")
              }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <label :for="'form-element-latitude-' + index">
              {{ $t("pages.physicalObjects.latitude") }}
            </label>
            <b-form-input
              :id="'form-element-latitude-' + index"
              v-model="item.latitude"
              type="number"
              @change="changed"
              @input="changed"
            />
            <label :for="'form-element-longitude-' + index">
              {{ $t("pages.physicalObjects.longitude") }}
            </label>
            <b-form-input
              :id="'form-element-longitude-' + index"
              v-model="item.longitude"
              type="number"
              @change="changed"
              @input="changed"
            />
          </b-col>
          <b-col sm="auto" align-self="end">
            <b-button
              v-t="'pages.physicalObjects.coordinatesdelete'"
              @click="deleteCoordinates(index)"
            />
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-list-group>
    <b-button
      v-if="content.length < 2"
      v-t="'pages.physicalObjects.coordinatesnew'"
      @click="addCoordinates"
    />
  </div>
</template>

<script>
export default {
  name: "CoordinatesComponent",
  props: {
    value: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      content: this.value
    };
  },
  watch: {
    value() {
      this.content = this.value;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit("input", this.content);
    },
    changed() {
      this.$emit("change");
    },
    addCoordinates() {
      this.content.push({
        current: this.content[0] ? !this.content[0].current : true,
        latitude: null,
        longitude: null
      });
      this.$emit("change");
    },
    deleteCoordinates(index) {
      this.content.splice(index, 1);
      this.$emit("change");
    },
    toggleCurrent() {
      this.content[0].current = !this.content[0].current;
      if (this.content[1]) {
        this.content[1].current = !this.content[1].current;
      }
      this.$emit("change");
    }
  }
};
</script>
